import React, { useRef } from "react";
import { FormHeader, Toggle } from "./index";
import { User, UserPhoto } from "../classes";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import { Grid, Button, TextField, Typography } from "@mui/material";
import { useUserPhotos } from "../hooks";
import Photos, { PhotoButton } from "./Photos";
import { useMe } from "../store";
import { UserComments } from "./UserComments";

interface ManagerFormProps {
  title: string;
  manager: User;
  setManager: React.Dispatch<React.SetStateAction<User>>;
  onSave: () => void;
  onDelete?: () => void;
}

export default function ManagerForm({
  title,
  manager,
  setManager,
  onSave,
  onDelete,
}: ManagerFormProps) {
  const addManagerEl = useRef<HTMLButtonElement>(null);

  const {
    photos,

    addPhoto,
    removePhoto,
    updatePhoto,
  } = useUserPhotos({ userId: manager.id });

  const { isAdmin } = useMe();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    switch (e.target.name) {
      case "isAdmin":
        const roles = [];
        if (e.target.checked) {
          roles.push("ADMIN");
        }
        setManager(new User({ ...manager, roles }));
        break;
      default:
        setManager(
          new User({
            ...manager,
            [e.target.name]: value,
          })
        );
        break;
    }
  };
  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    try {
      onSave();
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <>
      <FormHeader
        title={title}
        primaryButton={{
          onClick: () => addManagerEl.current?.click(),
          text: "Save Manager",
          startIcon: <SaveIcon />,
        }}
      />
      <div className="content">
        <form onSubmit={handleSubmit}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <TextField
                variant="outlined"
                fullWidth
                label="First Name"
                name="firstName"
                value={manager.firstName}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item>
              <TextField
                variant="outlined"
                fullWidth
                label="Last Name"
                name="lastName"
                value={manager.lastName}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item>
              <TextField
                variant="outlined"
                fullWidth
                label="Email"
                name="email"
                type="email"
                value={manager.email}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item>
              <TextField
                variant="outlined"
                fullWidth
                label="Address"
                name="address"
                value={manager.address || ""}
                onChange={handleChange}
              />
            </Grid>
            <Grid item>
              <TextField
                variant="outlined"
                fullWidth
                label="City"
                name="city"
                value={manager.city || ""}
                onChange={handleChange}
              />
            </Grid>
            <Grid item>
              <TextField
                variant="outlined"
                fullWidth
                label="State"
                name="state"
                value={manager.state || ""}
                onChange={handleChange}
              />
            </Grid>
            <Grid item>
              <TextField
                variant="outlined"
                fullWidth
                label="Zipcode"
                name="zipcode"
                value={manager.zipcode || ""}
                onChange={handleChange}
              />
            </Grid>
            <Grid item>
              <TextField
                variant="outlined"
                fullWidth
                label="Phone Number"
                name="phoneNumber"
                value={manager.phoneNumber || ""}
                onChange={handleChange}
              />
            </Grid>
            <Grid item>
              <Toggle
                label="Is Admin?"
                name="isAdmin"
                value={manager.roles.includes("ADMIN")}
                onChange={handleChange}
              />
            </Grid>

            {manager.id && (
              <Grid item>
                <Toggle
                  label="Is Disabled?"
                  name="disabled"
                  value={manager.disabled}
                  onChange={handleChange}
                />
              </Grid>
            )}

            <Grid item container>
              <Typography variant="h6">Photos</Typography>
              <Photos<UserPhoto>
                photos={photos}
                updatePhoto={updatePhoto}
                addPhoto={addPhoto}
                removePhoto={removePhoto}
                editable
                saveFirst={manager.id === undefined}
                PhotoButtonComponent={PhotoButton<UserPhoto>}
              />
            </Grid>

            <Grid item>
              <UserComments userId={manager.id} />
            </Grid>
          </Grid>

          {/* Used as a "submit" proxy for the Save button */}
          <button
            ref={addManagerEl}
            type="submit"
            style={{ display: "none" }}
          />
        </form>

        {isAdmin && manager.isPersisted() && (
          <Grid container justifyContent="center" sx={{ marginTop: 5 }}>
            <Button color="error" onClick={onDelete} startIcon={<DeleteIcon />}>
              Delete Manager
            </Button>
          </Grid>
        )}
      </div>
    </>
  );
}
